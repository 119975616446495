import React, { useEffect, useRef, useState } from 'react'
import side from "../../Assets/Image/corporate/side.png"
import out from "../../Assets/Image/corporate/out 01.png"
import noImage from "../../Assets/Image/corporate/user.png";
import edit from "../../Assets/Image/corporate/Edits.png";
import deletes from "../../Assets/Image/corporate/Deletes.png";
import Dialog from '../../Base-Component/Dialog/Dialog';
import back from "../../Assets/Image/corporate/background2.png"
import deleteswhite from "../../Assets/Image/corporate/deletewhite.png"
import FormSelect from '../../Base-Component/FormSelect/FormSelect';
import men1 from "../../Assets/Image/corporate/men6.png"
import men2 from "../../Assets/Image/corporate/men7.png"
import men3 from "../../Assets/Image/corporate/men8.png"
import men4 from "../../Assets/Image/corporate/men9.png"
import men5 from "../../Assets/Image/corporate/men10.png"
import tabedit from "../../Assets/Image/corporate/edit.png"
import tabdeletes from "../../Assets/Image/corporate/delete.png"
import nodata from "../../Assets/Image/corporate/nodata.png"
import search from "../../Assets/Image/corporate/Search.png"
import add from "../../Assets/Image/corporate/add.png"
import EyeSlash from "../../Assets/Image/corporate/slasheye.png"
import Eye from "../../Assets/Image/corporate/eye.png"
import tick from "../../Assets/Image/corporate/tick.png"
import cancel from "../../Assets/Image/corporate/cancel.png"
import { Circles } from 'react-loader-spinner';
import { Disclosure } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import { GET_USER_INFO, IMG_URL } from '../../Services/Api';
import axios from 'axios';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment/moment';
import {
    accountLogout,
    addCompanyAddress,
    changePassword,
    deleteCompanyAddress,
    editCompanyAddress,
    editCompanyInfo,
    editUserInfo,
    getCompanyAddress
} from '../../Services/ApiServices';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Setting() {

    const navigate = useNavigate()
    const scrollRef = useRef(null);

    const token = localStorage.getItem("corporate_token")
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isFlag, setIsFlag] = useState(0)
    const [eyes, setEyes] = useState(0)
    const [eyess, setEyess] = useState(0)
    const [eyesss, setEyesss] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState(3)

    const [image, setImage] = useState("")
    const [img, setImg] = useState("")
    const [searchData, setSearchData] = useState("")
    const [handelSelectId, setHandleSelectId] = useState("")
    const [handelSelectName, setHandleSelectName] = useState("")
    const [handelSelect, setHandleSelect] = useState("")
    const [addressId, setAddressId] = useState("")
    const [texts, setTexts] = useState("password")
    const [textss, setTextss] = useState("password")
    const [textsss, setTextsss] = useState("password")

    const [isAddAddress, setIsAddAddress] = useState(false)
    const [isUpdateAddress, setIsUpdateAddress] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [isAddUser, setIsAddUser] = useState(false)
    const [isDeleteUser, setIsDeleteUser] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadings, setIsLoadings] = useState(false)
    const [isLogOut, setIsLogOut] = useState(false)
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);

    const [employeess, setEmployeess] = useState({})
    const [formData, setFormData] = useState({})
    const [addreassDetails, setAddressDetails] = useState({})
    const [addreassCompany, setAddressCompany] = useState({})
    const [userDetails, setUserDetails] = useState({})
    const [phoneDetails, setPhoneDetails] = useState({
        isoCode: '',
        countryCode: '',
        phoneNumber: ''
    });

    const [addressList, setAddressList] = useState([])

    const [userData, setUserData] = useState([
        {
            id: "#6790",
            image: men1,
            name: "Esther Howard",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men2,
            name: "Cameron Williamson",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men3,
            name: "Jenny Wilson",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men4,
            name: "Guy Hawkins",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men5,
            name: "Savannah Nguyen",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Female",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men1,
            name: "Bessie Cooper",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
        {
            id: "#6790",
            image: men2,
            name: "Cody Fisher",
            email: "estherhoward15@gmail.com",
            contact_no: "+1 124584102",
            gender: "Male",
            last_check: "12 Mar, 2024",
        },
    ])

    const handleGetProfile = () => {
        axios.get(GET_USER_INFO)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info;
                    setPhoneDetails({
                        isoCode: data.isoCode,
                        countryCode: data.countryCode,
                        phoneNumber: data.phoneNumber.toString()
                    });
                    let obj = {
                        isoCode: data.isoCode,
                        countryCode: data.countryCode,
                        phoneNumber: data.phoneNumber.toString()
                    }
                    // setDatess(data.dob)
                    setUserDetails(res.data.info)
                }
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
            })
    }

    useEffect(() => {
        if (!token) {
            navigate("/corporatelogin")
        }
        handleGetProfile()
        handleAddressList()
    }, [])

    const handleAddressList = () => {
        const data = JSON.parse(localStorage.getItem("CORPORATE_INFO"))
        getCompanyAddress(data.companyId)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info
                    setAddressDetails(res.data.info)
                    setAddressList(data.companyAddresses)
                }
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
            })
    }

    const handleAddressDelete = () => {
        setIsLoading(true)
        let obj = { addressId: addressId }
        deleteCompanyAddress(obj)
            .then((res) => {
                if (res.status == 200) {
                    setModalOpen(false)
                    setIsFlag(1)
                    handleAddressList()
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressDetails({ ...addreassDetails, [name]: value })
    }

    const addressChange = (e) => {
        const { name, value } = e.target;
        setAddressCompany({ ...addreassCompany, [name]: value })
    }

    const faqData = [
        {
            question: 'How do I update my availability for appointments?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
        {
            question: 'What should I do if I need to reschedule an upcoming appointment?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
        {
            question: 'Can I view the full medical history of my clients?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
        {
            question: 'How are payments processed for my services?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
        {
            question: 'What is your refund policy?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
        {
            question: 'How can I ensure my safety when doing site visits?',
            answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam harum autem temporibus blanditiis ipsa in, asperiores eos quam maxime commodi molestias facilis fugit illo cupiditate eveniet quidem culpa! Nisi, voluptatibus?',
        },
    ];

    const handlePhoneChange = (value, country, e, formattedValue) => {
        setPhoneDetails(prevState => ({
            ...prevState,
            phoneNumber: value,
            isoCode: country.countryCode,
            countryCode: country.dialCode
        }));
        console.log("value >>", value)
    };

    const handleFile = (e) => {
        setImage(e.target.files[0])
        let imageData = URL.createObjectURL(e.target.files[0])
        setImg(imageData)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)

        const data = JSON.parse(localStorage.getItem("CORPORATE_INFO"))
        let obj = {
            companyId: data.companyId,
            streetAddress: addreassCompany.streetAddress,
            aptAddress: addreassCompany.aptAddress,
            city: addreassCompany.city,
            state: addreassCompany.state,
            zipCode: addreassCompany.zipCode,
            companyLat: "21.568988",
            companyLong: "72.568978"
        }

        addCompanyAddress(obj)
            .then((res) => {
                console.log("add >>", res)
                if (res.status == 200) {
                    setIsAddAddress(false)
                    setAddressCompany({})
                    setIsFlag(1)
                    handleAddressList()
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        setIsLoading(true)

        let obj = {
            addressId: addreassCompany.addressId,
            streetAddress: addreassCompany.streetAddress,
            aptAddress: addreassCompany.aptAddress,
            city: addreassCompany.city,
            state: addreassCompany.state,
            zipCode: addreassCompany.zipCode,
            companyLat: "21.568988",
            companyLong: "72.568978"
        }

        editCompanyAddress(obj)
            .then((res) => {
                console.log("add >>", res)
                if (res.status == 200) {
                    setIsUpdateAddress(false)
                    setAddressCompany({})
                    setIsFlag(1)
                    handleAddressList()
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const handleUpdateCompany = (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = new FormData()
        formData.append("companyId", addreassDetails.companyId)
        formData.append("companyName", addreassDetails.companyName)
        if (image) {
            formData.append("companyPic", image)
        }

        editCompanyInfo(formData)
            .then((res) => {
                console.log("add >>", res)
                if (res.status == 200) {
                    setIsFlag(1)
                    handleAddressList()
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'px-4 py-[7px] mx-1 bg-[#EA5F5F1A] rounded-md text-[#EA5F5F]' : 'px-4 py-[7px] mx-1 text-[#EA5F5F]'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handleEyes = () => {
        const flag = eyes == 0 ? 1 : 0
        setEyes(flag)

        const text = texts == "password" ? "text" : "password"
        setTexts(text)
    }

    const handleEyess = () => {
        const flag = eyess == 0 ? 1 : 0
        setEyess(flag)

        const text = textss == "password" ? "text" : "password"
        setTextss(text)
    }

    const handleEyesss = () => {
        const flag = eyesss == 0 ? 1 : 0
        setEyesss(flag)

        const text = textsss == "password" ? "text" : "password"
        setTextsss(text)
    }

    const handleDeleteUser = (data) => {
        setEmployeess(data)
        setIsDeleteUser(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value })

        if (name === 'newpass' || name === "confinewpass") {
            setIsLengthValid(value.length >= 8);
            setHasNumber(/\d/.test(value));
            setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
        }
    };

    const scroll = (direction) => {
        if (scrollRef.current) {
            const { scrollLeft, clientWidth } = scrollRef.current;
            const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
            scrollRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
        }
    };

    const startDragging = (e) => {
        if (scrollRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - scrollRef.current.offsetLeft);
            setScrollLeft(scrollRef.current.scrollLeft);
            document.body.style.userSelect = 'none'; // Disable text selection
        }
    };

    const stopDragging = () => {
        setIsDragging(false);
        document.body.style.userSelect = ''; // Re-enable text selection
    };

    const onDragging = (e) => {
        if (!isDragging || !scrollRef.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2; // scroll-fast
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        lastName: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        mobileNumber: Yup.string().required("(Required)"), // Basic validation, modify as needed
        emailAddress: Yup.string()
            .email("Invalid email address")
            .matches(/^([a-zA-Z0-9._%+-]+)@gmail\.com$/, "Only Gmail addresses with .com domain are allowed")
            .required("(Required)"),
        dateOfBirth: Yup
            .date()
            .max(new Date(), "Date of birth cannot be in the future")
            .required("(Required)"),
        profileImage: Yup.mixed(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            mobileNumber: `${userDetails.countryCode}${userDetails.phoneNumber}`,
            emailAddress: userDetails.emailId,
            dateOfBirth: moment(userDetails.dob).format("YYYY-MM-DD"),
            profileImage: userDetails.profilePic,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const rawPhone = phoneDetails.phoneNumber.replace(phoneDetails.countryCode, "");
            const data = JSON.parse(localStorage.getItem("CORPORATE_INFO"))
            setIsLoading(true)

            const formData = new FormData()
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            if (image) {
                formData.append("profilePic", image)
            }

            editUserInfo(formData)
                .then((res) => {
                    console.log("add >>", res)
                    if (res.status == 200) {
                        const pic = res.data.info
                        handleGetProfile()
                        let obj = {
                            userId: data.userId,
                            companyId: data.companyId,
                            profilePic: pic.profilePic
                        }
                        localStorage.setItem("CORPORATE_INFO", JSON.stringify(obj))
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem("corporate_token")
                        navigate("/corporatelogin")
                    } else {
                        toast.error(err.response.data.message)
                    }
                    setIsLoading(false)
                })
        },
    });

    const handleEdit = (data) => {
        setAddressCompany(data)
        setIsUpdateAddress(true)
    }

    const handleChangePassword = (e) => {
        setIsLoading(true)
        e.preventDefault()
        let param = {
            oldpass: formData.oldPassword,
            newpass: formData.newpass
        }

        if (formData.newpass != formData.confinewpass) {
            toast.error("New Password & Confirm New Password Don't Match")
            setIsLoading(false)
        } else {
            changePassword(param)
                .then((res) => {
                    console.log("add >>", res)
                    if (res.status == 200) {
                        setFormData({})
                        setHasSpecialChar(false)
                        setHasNumber(false)
                        setIsLengthValid(false)
                        toast.success(res.data.message)
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem("corporate_token")
                        navigate("/corporatelogin")
                    } else {
                        toast.error(err.response.data.message)
                    }
                    setIsLoading(false)
                })
        }
    }

    const handleLogout = () => {
        setIsLoadings(true)
        accountLogout()
            .then((res) => {
                if (res.status == 200) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin");
                }
                setIsLoadings(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoadings(false)
            })
    };

    return (
        <>
            <div className="grid grid-cols-12 gap-4 md:m-4 m-1 fontNew">
                <div className="col-span-12 lg:hidden block">
                    <div className="flex items-center overflow-hidden w-full relative">
                        <button
                            className="bg-transparent border-2 border-[#EA5F5F] text-[#EA5F5F] text-xl w-7 h-7 flex items-center justify-center rounded-full cursor-pointer px-3"
                            onClick={() => scroll('left')}
                        >
                            {'<'}
                        </button>
                        <div
                            className="flex overflow-x-auto scroll-smooth whitespace-nowrap scrollbar-hide py-2"
                            ref={scrollRef}
                            onMouseDown={startDragging}
                            onMouseLeave={stopDragging}
                            onMouseUp={stopDragging}
                            onMouseMove={onDragging}
                        >
                            <div onClick={() => { setIsFlag(0); setImage(""); setImg("") }} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 0 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                Personal Information
                            </div>
                            <div onClick={() => { setIsFlag(1); setImage(""); setImg("") }} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 1 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                Company Details
                            </div>
                            <div onClick={() => setIsFlag(2)} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 2 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                Manage Users
                            </div>
                            <div onClick={() => setIsFlag(3)} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 3 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                Change Password
                            </div>
                            <div onClick={() => setIsFlag(4)} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 4 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                FAQs
                            </div>
                            <div onClick={() => setIsFlag(5)} className={`px-5 py-2 mx-2 rounded-full cursor-pointer ${isFlag == 5 ? "bg-[#EA5F5F] text-white" : "bg-white text-black"}`}>
                                Terms & Conditions
                            </div>
                        </div>
                        <button
                            className="bg-transparent border-2 border-[#EA5F5F] text-[#EA5F5F] text-xl w-7 h-7 flex items-center justify-center rounded-full cursor-pointer px-3"
                            onClick={() => scroll('right')}
                        >
                            {'>'}
                        </button>
                    </div>
                </div>
                <div className="lg:col-span-3 col-span-12 lg:block hidden">
                    <div className='bg-white bg-contain rounded-lg px-6 py-3'>
                        <div onClick={() => { setIsFlag(0); setImage(""); setImg("") }} className={isFlag == 0 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>Personal Information</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => { setIsFlag(1); setImage(""); setImg("") }} className={isFlag == 1 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>Company Details</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => setIsFlag(2)} className={isFlag == 2 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>Manage Users</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => setIsFlag(3)} className={isFlag == 3 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>Change Password</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => setIsFlag(4)} className={isFlag == 4 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>FAQs</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => setIsFlag(5)} className={isFlag == 5 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium'>Terms & Conditions</p>
                            <img src={side} className='w-2' alt="" />
                        </div>
                        <div onClick={() => setIsLogOut(true)} className={isFlag == 6 ? 'flex items-center bg-[#F4F5FA] py-4 px-6 rounded-lg justify-between my-5' : 'flex items-center justify-between py-4 px-6 my-5 cursor-pointer'}>
                            <p className='font-medium text-[#EA0707]'>Log Out</p>
                            <img src={out} className='w-7' alt="" />
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-9 col-span-12">
                    {
                        isFlag == 0 ?
                            <div className='bg-white sm:p-6 p-3 rounded-lg'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='flex items-center'>
                                        <img src={img ? img : userDetails.profilePic ? IMG_URL + userDetails.profilePic : noImage} crossOrigin='anonymous' className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full object-cover' alt="" />
                                        <div className='ms-5'>
                                            <h4 className='text-base font-semibold'>Profile Image</h4>
                                            <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                            <div className='mt-6'>
                                                <label htmlFor="upload-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] sm:py-3 py-2 sm:px-5 px-3 rounded-lg text-sm font-normal'>Upload image</label>
                                                <input type="file" onChange={(e) => handleFile(e)} className='hidden' id='upload-photo' accept='image/*' />
                                                {formik.touched.profileImage && formik.errors.profileImage ? (
                                                    <div className="text-red-600 text-sm mt-4">{formik.errors.profileImage}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 mt-6">
                                        <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                            <div className='flex text-sm font-medium mb-2'>
                                                <label className='mr-2' htmlFor="firstName">First Name</label>
                                            </div>
                                            <input name="firstName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.firstName}
                                                type="text"
                                                className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                            {formik.touched.firstName && formik.errors.firstName ? (
                                                <div className="text-red-600 text-sm mt-1">{formik.errors.firstName}</div>
                                            ) : null}
                                        </div>
                                        <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                            <div className='flex text-sm font-medium mb-2'>
                                                <label className='mr-2' htmlFor="lastName">Last Name</label>
                                            </div>
                                            <input name="lastName" value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                            {formik.touched.lastName && formik.errors.lastName ? (
                                                <div className="text-red-600 text-sm mt-1">{formik.errors.lastName}</div>
                                            ) : null}
                                        </div>
                                        <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                            <div className='flex text-sm font-medium mb-2'>
                                                <label className='mr-2' htmlFor="emailAddress">Email</label>
                                            </div>
                                            <input name="emailAddress"
                                                // onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.emailAddress}
                                                type="text"
                                                className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                            {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                <div className="text-red-600 text-sm mt-1">{formik.errors.emailAddress}</div>
                                            ) : null}
                                        </div>
                                        <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                            <div className='flex text-sm font-medium mb-2'>
                                                <label className='mr-2' htmlFor="employeeId">Mobile Number</label>
                                            </div>
                                            <PhoneInput
                                                country={phoneDetails.isoCode}
                                                value={formik.values.mobileNumber}
                                                // onChange={handlePhoneChange}
                                                inputProps={{ name: 'mobileNumber', required: true, autoFocus: true }}
                                                className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]'
                                            />
                                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                                <div className="text-red-600 text-sm mt-1">{formik.errors.mobileNumber}</div>
                                            ) : null}
                                        </div>
                                        <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                            <div className='flex text-sm font-medium mb-2'>
                                                <label className='mr-2' htmlFor="dateOfBirth">Date of Birth</label>
                                            </div>
                                            <div className="flex space-x-2">
                                                <input name="dateOfBirth"
                                                    // onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.dateOfBirth}
                                                    type="date"
                                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                                                    <div className="text-red-600 text-sm mt-1">{formik.errors.dateOfBirth}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" disabled={isLoading} className="btn btn-primary sm:mx-4 mx-0 mt-10 bg-[#00987C] w-[226px] h-[42px] rounded-lg text-white text-sm">
                                        {isLoading ? (
                                            <div className='flex justify-center'>
                                                <Circles
                                                    width={25}
                                                    height={25}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </form>
                            </div>
                            : isFlag == 1 ?
                                <div className='bg-white sm:p-6 p-3 rounded-lg'>
                                    <form onSubmit={handleUpdateCompany}>
                                        <div className='flex items-center'>
                                            <img src={img ? img : addreassDetails.companyPic ? IMG_URL + addreassDetails.companyPic : noImage} crossOrigin='anonymous' className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] object-cover rounded-full' alt="" />
                                            <div className='ms-5'>
                                                <h4 className='text-base font-semibold'>Company Image</h4>
                                                <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                                <div className='mt-6'>
                                                    <label htmlFor="upload-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] py-3 px-5 rounded-lg text-sm font-normal'>Upload image</label>
                                                    <input type="file" onChange={(e) => handleFile(e)} className='hidden' id='upload-photo' accept='image/*' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 mt-6">
                                            <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                                <div className='flex text-sm font-medium mb-2'>
                                                    <label className='mr-2' htmlFor="companyName">Company Name</label>
                                                </div>
                                                <input name="companyName" type="text" required value={addreassDetails.companyName} onChange={handleAddressChange} className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                            </div>
                                            <div className='sm:col-span-6 col-span-12 sm:mx-4 mx-0 my-3'>
                                                <div className='flex text-sm font-medium mb-2'>
                                                    <label className='mr-2' htmlFor="companyEmailId">Company Email</label>
                                                </div>
                                                <input name="companyEmailId" type="text" value={addreassDetails.companyEmailId} className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]" />
                                            </div>
                                        </div>
                                        <button type="submit" disabled={isLoading} className="btn btn-primary sm:mx-4 mx-0 my-5 bg-[#00987C] w-[226px] h-[42px] rounded-lg text-white text-sm">
                                            {isLoading ? (
                                                <div className='flex justify-center'>
                                                    <Circles
                                                        width={25}
                                                        height={25}
                                                        color="#fff"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                            ) : (
                                                "Save"
                                            )}
                                        </button>
                                    </form>
                                    <div className='sm:mx-4 mx-0 my-6'>
                                        <h1 className='text-lg font-medium border-b-2 border-gray-100 pb-5'>Company Sites</h1>

                                        <div className='grid grid-cols-12 py-4'>
                                            {
                                                addressList.map((item, index) => {
                                                    return (
                                                        <div key={index} className='2xl:col-span-3 xl:col-span-4 md:col-span-6 col-span-12 bg-[#F6F6F6] p-5 rounded-lg sm:m-3 my-3'>
                                                            <div className='flex items-center justify-end cursor-pointer'>
                                                                <img src={edit} onClick={() => handleEdit(item)} className='w-4' alt="" />
                                                                <img src={deletes}
                                                                    onClick={() => { setModalOpen(true); setAddressId(item.addressId) }}
                                                                    className='w-4 ms-4' alt="" />
                                                            </div>
                                                            <div className='sm:pr-10 pr-0 py-5'>
                                                                <p className='font-medium break-words'>{`${item.aptAddress}, ${item.streetAddress}, ${item.city}, ${item.state}-${item.zipCode}`}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <button
                                            onClick={() => setIsAddAddress(true)}
                                            className='bg-transparent text-sm border-2 border-[#029D79] text-[#029D79] px-18 py-3 font-medium rounded-lg'>
                                            Add New Site
                                        </button>
                                    </div>
                                </div>
                                : isFlag == 2 ?
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 fontNew duration-300 cursor-pointer'>
                                            <div className='flex flex-wrap justify-between items-center'>
                                                <div>
                                                    <h1 className='text-2xl sm:mb-0 mb-3 font-medium'>Users</h1>
                                                </div>
                                                <div className='flex flex-wrap items-center '>
                                                    <button onClick={() => setIsAddUser(true)} className='bg-[#EA5F5F] text-white flex items-center py-1 px-3 border-2 mr-3 border-[#EA5F5F] rounded-xl' >
                                                        <img src={add} className='w-6 mr-2' alt="" />Add User
                                                    </button>
                                                    <div className='flex sm:w-70 w-full h-10 px-4 rounded-xl bg-white my-3 items-center'>
                                                        <input
                                                            type="text"
                                                            required
                                                            className='bg-transparent border-none outline-none sm:w-70 w-full h-10 text-sm fontNew'
                                                            placeholder='Search...'
                                                            name='search_text'
                                                            value={searchData}
                                                            onChange={(e) => setSearchData(e.target.value)}
                                                        />
                                                        <img src={search} className='p-2 w-10 cursor-pointer' alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            userData == "" ?
                                                <div className='col-span-12 h-[50vh] justify-center flex items-center'>
                                                    <img src={nodata} className='w-28' alt="" />
                                                </div>
                                                :
                                                <>
                                                    <div className="col-span-12 overflow-auto">
                                                        <div
                                                            className='w-full overflow-auto'
                                                            onMouseDown={startDragging}
                                                            onMouseLeave={stopDragging}
                                                            onMouseUp={stopDragging}
                                                            onMouseMove={onDragging}
                                                            ref={scrollRef}
                                                        >
                                                            <table className='w-full border-separate border-spacing-y-3'>
                                                                <tr className='bg-white'>
                                                                    <th className='py-4 px-5 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        <FormSelect id="page" name="page_no" className="selects-arrow-hidden w-20 outline-none text-base" value={handelSelectId} onChange={(e) => setHandleSelectId(e.target.value)}>
                                                                            <option value="5">ID</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                        </FormSelect>
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        <FormSelect id="page" name="page_no" className="selects-arrow-hidden outline-none w-28 text-base" value={handelSelectName} onChange={(e) => setHandleSelectName(e.target.value)}>
                                                                            <option value="5">Name</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                        </FormSelect>
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        Email
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        <div className='text-center'>
                                                                            Action
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <tbody>
                                                                    {
                                                                        userData.map((item, index) => {
                                                                            return (
                                                                                <tr key={index} className='bg-white font-medium'>
                                                                                    <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                        <div className='whitespace-nowrap'>
                                                                                            {item.id}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                        <div className='whitespace-nowrap w-52 flex items-center'>
                                                                                            <img src={item.image} className='w-10' alt="" />
                                                                                            <p className='text-base ms-3'>{item.name}</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                        <div className='whitespace-nowrap'>
                                                                                            <p className='text-base'>{item.email}</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-start flex justify-center px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                        <div className='flex items-center w-52 justify-center cursor-pointer'>
                                                                                            <div>
                                                                                                <img src={tabedit} className='lg:w-9 w-8 mx-4' alt="" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <img onClick={() => handleDeleteUser(item)} src={tabdeletes} className='lg:w-9 w-8' alt="" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='col-span-12 flex flex-wrap items-center sm:justify-between justify-center bg-white py-4 sm:px-8 px-0 rounded-lg'>
                                                        <div className='flex items-center'>
                                                            <p>Show Result</p>
                                                            <div>
                                                                <FormSelect id="page" name="page_no" className="select-arrow-hidden w-16 outline-none px-2 ms-3 text-base font-semibold" value={handelSelect} onChange={(e) => setHandleSelect(e.target.value)}>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                </FormSelect>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center cursor-pointer'>
                                                            <p onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                                                Previous
                                                            </p>
                                                            <div className="flex items-center mx-6">
                                                                {renderPaginationButtonss()}
                                                            </div>
                                                            <p onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                                                Next
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    : isFlag == 3 ?
                                        <div className='text-start flex justify-start items-center'>
                                            <div className='bg-white rounded-lg sm:p-6 p-3'>
                                                <p className='fontNew text-base text-[#7E7E7E] py-3'>Your new password must be unique from those previously <br /> used.</p>
                                                <form onSubmit={handleChangePassword}>
                                                    <div className='mt-3 mb-6'>
                                                        <div className='my-3'>
                                                            <p className='text-sm font-medium mb-3'>Current Password</p>
                                                            <div className='flex items-center'>
                                                                <input
                                                                    type={texts}
                                                                    required
                                                                    className='bg-transparent ps-4 pe-12 rounded-md border-2 w-full outline-[#00987C] h-[54px] text-base'
                                                                    placeholder='Password'
                                                                    name='oldPassword'
                                                                    value={formData.oldPassword || ""}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                {
                                                                    eyes == 0 ?
                                                                        <img src={EyeSlash} onClick={handleEyes} className='p-2 w-10 h-10 -ms-12 cursor-pointer' alt="" />
                                                                        :
                                                                        <img src={Eye} onClick={handleEyes} className='p-2 w-10 h-8 -ms-12 cursor-pointer' alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='my-3'>
                                                            <p className='text-sm font-medium mb-3'>New Password</p>
                                                            <div className='flex items-center'>
                                                                <input
                                                                    type={textss}
                                                                    required
                                                                    className='bg-transparent ps-4 pe-12 rounded-md border-2 w-full outline-[#00987C] h-[54px] text-base'
                                                                    placeholder='Password'
                                                                    name='newpass'
                                                                    value={formData.newpass || ""}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                {
                                                                    eyess == 0 ?
                                                                        <img src={EyeSlash} onClick={handleEyess} className='p-2 w-10 h-10 -ms-12 cursor-pointer' alt="" />
                                                                        :
                                                                        <img src={Eye} onClick={handleEyess} className='p-2 w-10 h-8 -ms-12 cursor-pointer' alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='my-3'>
                                                            <p className='text-sm font-medium mb-3'>Confirm New Password</p>
                                                            <div className='flex items-center'>
                                                                <input
                                                                    type={textsss}
                                                                    required
                                                                    className='bg-transparent ps-4 pe-12 rounded-md border-2 w-full outline-[#00987C] h-[54px] text-base'
                                                                    placeholder='Password'
                                                                    name='confinewpass'
                                                                    value={formData.confinewpass || ""}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                {
                                                                    eyesss == 0 ?
                                                                        <img src={EyeSlash} onClick={handleEyesss} className='p-2 w-10 h-10 -ms-12 cursor-pointer' alt="" />
                                                                        :
                                                                        <img src={Eye} onClick={handleEyesss} className='p-2 w-10 h-8 -ms-12 cursor-pointer' alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='my-3'>
                                                        <div className='flex items-center'>
                                                            <img src={isLengthValid ? tick : cancel} className='h-4 w-4' alt="" />
                                                            <p className='ps-3 text-sm'>8 or more characters</p>
                                                        </div>
                                                        <div className='flex items-center my-3'>
                                                            <img src={hasNumber ? tick : cancel} className='h-4 w-4' alt="" />
                                                            <p className='ps-3 text-sm'>Contain at least 1 number</p>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            <img src={hasSpecialChar ? tick : cancel} className='h-4 w-4' alt="" />
                                                            <p className='ps-3 text-sm'>Contain at least 1 special character</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button type="submit" disabled={isLoading} className="btn btn-primary my-5 bg-[#00987C] w-[226px] h-[42px] rounded-lg text-white text-sm">
                                                            {
                                                                isLoading ?
                                                                    <div className='flex justify-center'>
                                                                        <Circles
                                                                            width={23}
                                                                            height={23}
                                                                            color="#fff"
                                                                            ariaLabel="circles-loading"
                                                                            wrapperStyle={{}}
                                                                            visible={true}
                                                                            wrapperClass=""
                                                                        />
                                                                    </div>
                                                                    :
                                                                    "Update"
                                                            }
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                        : isFlag == 4 ?
                                            <div className='bg-white sm:p-6 p-3 rounded-lg'>
                                                <h1 className='text-xl font-medium pb-5'>FAQs</h1>
                                                {faqData.map((faq, index) => (
                                                    <div className='py-4 border-b-2 border-[#e9e9e985]' key={index}>
                                                        <Disclosure>
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="group flex w-full items-center justify-between">
                                                                        <span className="sm:text-base text-sm text-start font-medium text-black group-hover:text-black/80">
                                                                            {faq.question}
                                                                        </span>
                                                                        <span className="text-2xl text-[#00987C]">
                                                                            {open ? '-' : '+'}
                                                                        </span>
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel className="mt-2 text-sm text-gray-500">
                                                                        {faq.answer}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className='bg-white sm:p-6 p-3 rounded-lg'>
                                                <h1 className='text-xl font-semibold pb-5'>Terms & Conditions</h1>
                                                <div>
                                                    <h1 className='text-lg font-semibold'>Lorem Ipsum is simply dummy text of the printing and typesetting industry?</h1>
                                                    <p className='text-sm py-5 text-gray-500'>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                    </p>
                                                    <p className='text-sm py-5 text-gray-500'>
                                                        Contrary to popular belief, Lorem Ipsum is not simply random text.
                                                        It has roots in a piece of classical Latin literature from 45 BC,
                                                        making it over 2000 years old. Richard McClintock, a Latin professor at
                                                        Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                                                        consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                                                        discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                                                        Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is
                                                        a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum,
                                                        "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                                    </p>
                                                </div>
                                                <div>
                                                    <h1 className='text-lg font-semibold'>Lorem Ipsum is simply dummy</h1>
                                                    <p className='text-sm py-5 text-gray-500'>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                                        remaining essentially unchanged. Contrary to popular belief, Lorem Ipsum is not simply random text
                                                    </p>
                                                </div>
                                                <div>
                                                    <h1 className='text-lg font-semibold'>Lorem Ipsum is simply dummy</h1>
                                                    <p className='text-sm py-5 text-gray-500'>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                    </p>
                                                    <p className='text-sm py-5 text-gray-500'>
                                                        Contrary to popular belief, Lorem Ipsum is not simply random text.
                                                        It has roots in a piece of classical Latin literature from 45 BC,
                                                        making it over 2000 years old. Richard McClintock, a Latin professor at
                                                        Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                                                        consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                                                        discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                                                        Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is
                                                        a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum,
                                                        "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                                    </p>
                                                </div>
                                            </div>
                    }
                </div>
            </div>

            <Dialog
                open={isAddAddress}
                onClose={() => setIsAddAddress(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white sm:p-8 p-3 fontNew sm:w-auto w-[350px] sm:h-auto h-[530px] overflow-auto">
                        <h1 className='text-lg font-semibold mb-6'>Add New Site</h1>
                        <form onSubmit={handleSubmit}>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Street Address</p>
                                    <input required type="text" onChange={(e) => addressChange(e)} name='streetAddress' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Apt, Suite, etc...</p>
                                    <input required type="text" onChange={(e) => addressChange(e)} name='aptAddress' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>City</p>
                                    <input required type="text" onChange={(e) => addressChange(e)} name='city' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>State</p>
                                    <input required type="text" onChange={(e) => addressChange(e)} name='state' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Zip Code</p>
                                    <input required type="text" onChange={(e) => addressChange(e)} name='zipCode' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                            </div>
                            <div className='mt-6 flex items-center'>
                                <button type='submit' disabled={isLoading} className='bg-[#139E84] text-white w-[130px] h-[42px] rounded-lg'>
                                    {isLoading ? (
                                        <div className='flex justify-center'>
                                            <Circles
                                                width={25}
                                                height={25}
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass=""
                                            />
                                        </div>
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                                <button type='button' onClick={() => setIsAddAddress(false)} className='bg-[#F4F5FA] w-[130px] h-[42px] rounded-lg ms-5'>Close</button>
                            </div>
                        </form>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isUpdateAddress}
                onClose={() => setIsUpdateAddress(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white sm:p-8 p-3 fontNew sm:w-auto w-[350px] sm:h-auto h-[530px] overflow-auto">
                        <h1 className='text-lg font-semibold mb-6'>Add New Site</h1>
                        <form onSubmit={handleUpdate}>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Street Address</p>
                                    <input required type="text" value={addreassCompany.streetAddress} onChange={(e) => addressChange(e)} name='streetAddress' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Apt, Suite, etc...</p>
                                    <input required type="text" value={addreassCompany.aptAddress} onChange={(e) => addressChange(e)} name='aptAddress' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>City</p>
                                    <input required type="text" value={addreassCompany.city} onChange={(e) => addressChange(e)} name='city' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>State</p>
                                    <input required type="text" value={addreassCompany.state} onChange={(e) => addressChange(e)} name='state' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Zip Code</p>
                                    <input required type="text" value={addreassCompany.zipCode} onChange={(e) => addressChange(e)} name='zipCode' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                            </div>
                            <div className='mt-6 flex items-center'>
                                <button type='submit' disabled={isLoading} className='bg-[#139E84] text-white w-[130px] h-[42px] rounded-lg'>
                                    {isLoading ? (
                                        <div className='flex justify-center'>
                                            <Circles
                                                width={25}
                                                height={25}
                                                color="#fff"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                visible={true}
                                                wrapperClass=""
                                            />
                                        </div>
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                                <button type='button' onClick={() => setIsUpdateAddress(false)} className='bg-[#F4F5FA] w-[130px] h-[42px] rounded-lg ms-5'>Close</button>
                            </div>
                        </form>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isAddUser}
                onClose={() => setIsAddUser(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white md:p-8 p-4 sm:w-auto w-[350px] sm:h-auto h-[530px] overflow-auto fontNew">
                        <h1 className='text-lg font-semibold mb-6'>User</h1>
                        <form onSubmit={handleSubmit}>
                            <div className='grid grid-cols-12 '>
                                <div className='col-span-12 flex items-center mx-2 my-3'>
                                    {
                                        img ?
                                            <img src={img} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full object-cover' alt="" />
                                            :
                                            <img src={noImage} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full' alt="" />
                                    }
                                    <div className='ms-5'>
                                        <h4 className='text-base font-semibold'>Profile Image</h4>
                                        <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                        <div className='mt-6'>
                                            <label htmlFor="upload-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] py-3 px-5 rounded-lg text-sm font-normal'>Upload image</label>
                                            <input type="file" onChange={(e) => handleFile(e)} className='hidden' id='upload-photo' accept='image/*' />
                                        </div>
                                    </div>
                                </div>
                                <div className='md:col-span-6 col-span-12 md:mx-2 mx-0 my-3'>
                                    <p className='text-sm font-medium mb-3'>First Name</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='md:col-span-6 col-span-12 md:mx-2 mx-0 my-3'>
                                    <p className='text-sm font-medium mb-3'>Last Name</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 md:mx-2 mx-0 my-3'>
                                    <p className='text-sm font-medium mb-3'>Email</p>
                                    <input required type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' />
                                </div>
                                <div className='col-span-12 md:mx-2 mx-0 my-3'>
                                    <p className='text-sm font-medium mb-3'>Password</p>
                                    <div className='flex items-center'>
                                        <input
                                            type={texts}
                                            // required
                                            className='bg-transparent ps-4 pe-12 rounded-md border-2 w-full outline-[#00987C] h-[54px] text-base'
                                            placeholder='Password'
                                            name='password'
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {
                                            eyes == 0 ?
                                                <img src={EyeSlash} onClick={handleEyes} className='p-2 w-10 h-10 -ms-12 cursor-pointer' alt="" />
                                                :
                                                <img src={Eye} onClick={handleEyes} className='p-2 w-10 h-8 -ms-12 cursor-pointer' alt="" />
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 md:mx-2 mx-0 my-3'>
                                    <p className='text-sm font-medium mb-3'>Confirm Password</p>
                                    <div className='flex items-center'>
                                        <input
                                            type={textss}
                                            // required
                                            className='bg-transparent ps-4 pe-12 rounded-md border-2 w-full outline-[#00987C] h-[54px] text-base'
                                            placeholder='Password'
                                            name='password'
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {
                                            eyes == 0 ?
                                                <img src={EyeSlash} onClick={handleEyess} className='p-2 w-10 h-10 -ms-12 cursor-pointer' alt="" />
                                                :
                                                <img src={Eye} onClick={handleEyess} className='p-2 w-10 h-8 -ms-12 cursor-pointer' alt="" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='mt-6'>
                                <button type='submit' className='bg-[#00987C] text-white py-2 px-7 rounded-lg'>Submit</button>
                                <button type='button' onClick={() => setIsAddUser(false)} className='bg-[#F4F5FA] py-2 px-7 rounded-lg ms-5'>Close</button>
                            </div>
                        </form>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white">
                        <div>
                            <img src={back} className='w-auto relative' alt="" />
                            <div className='flex justify-center'>
                                <img src={deleteswhite} className='absolute sm:w-24 sm:h-24 w-14 h-14 lg:-mt-36 md:-mt-30 sm:-mt-36 -mt-24 ' alt="" />
                            </div>
                        </div>
                        <div className='flex justify-center text-center my-4 sm:px-16 px-5 fontNew'>
                            <div>
                                <h1 className='text-xl font-semibold text-black mt-6'>Delete Site?</h1>
                                <p className='text-sm text-gray-500 my-3'>Are you sure you want to delete the site from your company?</p>
                                <div className='mt-16 flex items-center mb-10'>
                                    <button className='bg-[#F4F5FA] h-[50px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2' onClick={() => setModalOpen(false)}>Close</button>
                                    <button className='bg-[#EA5F5F] h-[50px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2 text-white' onClick={handleAddressDelete}>
                                        {isLoading ? (
                                            <div className='flex justify-center'>
                                                <Circles
                                                    width={25}
                                                    height={25}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            "Yes, Delete"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isDeleteUser}
                onClose={() => setIsDeleteUser(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white">
                        <div>
                            <img src={back} className='w-auto relative' alt="" />
                            <div className='flex justify-center'>
                                <img src={employeess.image} className='absolute sm:w-24 sm:h-24 w-14 h-14 lg:-mt-36 md:-mt-30 sm:-mt-36 -mt-24 rounded-full border-2 ' alt="" />
                            </div>
                        </div>
                        <div className='flex justify-center text-center my-4 sm:px-16 px-5 fontNew'>
                            <div>
                                <h1 className='text-xl font-semibold text-black mt-6'>Delete User?</h1>
                                <p className='text-sm text-gray-500 my-3'>Are you sure want to delete {employeess.name} from the User?</p>
                                <div className='mt-16 mb-10'>
                                    <button className='bg-[#F4F5FA] py-[14px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2' onClick={() => setIsDeleteUser(false)}>Close</button>
                                    <button className='bg-[#EA5F5F] py-[14px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2 text-white' onClick={() => setIsDeleteUser(false)}>Yes, Delete</button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isLogOut}
                onClose={() => setIsLogOut(false)}
                size="2xl"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white">
                        <div className='flex justify-center text-center my-4 px-16 fontNew'>
                            <div>
                                <h1 className='text-xl font-semibold text-black mt-6'>Log Out?</h1>
                                <p className='text-sm text-gray-500 my-3'>Are you certain you wish to proceed with <br /> logging out?</p>
                                <div className='mt-13 flex items-center mb-5'>
                                    <button className='bg-[#F4F5FA] py-[14px] w-36 text-sm rounded-xl mx-3' onClick={() => setIsLogOut(false)}>Close</button>
                                    <button className='bg-[#EA5F5F] h-[50px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2 text-white' disabled={isLoadings} onClick={handleLogout}>
                                        {isLoadings ? (
                                            <div className='flex justify-center'>
                                                <Circles
                                                    width={25}
                                                    height={25}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            "Yes, Log Out"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog >
        </>
    )
}

export default Setting