import React, { useEffect, useRef, useState } from 'react'
import color1 from "../../Assets/Image/corporate/color1.png"
import color2 from "../../Assets/Image/corporate/color2.png"
import color3 from "../../Assets/Image/corporate/color3.png"
import color4 from "../../Assets/Image/corporate/color4.png"
import color5 from "../../Assets/Image/corporate/color5.png"
import { useNavigate } from 'react-router-dom'
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from 'moment/moment'
import "./Calendar.css"
import toast from 'react-hot-toast'
import { getCalenderList } from '../../Services/ApiServices'
import Dialog from '../../Base-Component/Dialog/Dialog'
import { newName } from '../../Services/Api'
import Close from "../../Assets/Image/corporate/close.png"

function Calender() {

    const navigate = useNavigate()
    const calendarRef = useRef(null);
    const [loader, setLoader] = useState(false)
    const [isModelOpen, setIsModelOpen] = useState(false)
    const [selectStatus, setSelectStatus] = useState(1)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [currentDate, setCurrentDate] = useState();
    const [requestDetails, setRequestDetails] = useState({})
    const [calendarData, setCalendarData] = useState([])
    const [calendarList, setCalendarList] = useState([])

    const token = localStorage.getItem("corporate_token");

    const handleCalendarList = () => {
        setLoader(true)
        if (selectStatus == 1) {
            let param = {
                filterType: "day",
                date: currentDate || moment(Date.now()).format("YYYY-MM-DD")
            }
            getCalenderList(param)
                .then((res) => {
                    if (res.status == 200) {
                        const data = res.data.info;
                        console.log("data1 >>", data)
                        const events = data?.map((item) => ({
                            start:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.startTime,
                            end:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.endTime,
                            username: item?.requestServices[0]?.healthService?.serviceName,
                            requestStatus: item.requestStatus,
                            ids: item?.requestId
                        }));
                        setCalendarData(events);
                        setCalendarList(data)
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem("corporate_token")
                        navigate("/corporatelogin")
                    } else {
                        toast.error(err.response.data.message)
                    }
                    setLoader(false)
                })
        } else if (selectStatus == 2) {
            let param = {
                filterType: "week",
                startDate: startDate,
                endDate: endDate
            }
            getCalenderList(param)
                .then((res) => {
                    if (res.status == 200) {
                        const data = res.data.info;
                        console.log("data2 >>", data)
                        const events = data?.map((item) => ({
                            start:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.startTime,
                            end:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.endTime,
                            username: item?.requestServices[0]?.healthService?.serviceName,
                            requestStatus: item.requestStatus,
                            ids: item?.requestId
                        }));
                        setCalendarData(events);
                        setCalendarList(data)
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem("corporate_token")
                        navigate("/corporatelogin")
                    } else {
                        toast.error(err.response.data.message)
                    }
                    setLoader(false)
                })
        } else {
            let param = {
                filterType: "month",
                month: currentDate
            }
            getCalenderList(param)
                .then((res) => {
                    if (res.status == 200) {
                        const data = res.data.info;
                        console.log("data3 >>", data)
                        const events = data?.map((item) => ({
                            start:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.startTime,
                            end:
                                moment(item.requestDate).format("YYYY-MM-DD") + "T" + item.endTime,
                            username: item?.requestServices[0]?.healthService?.serviceName,
                            requestStatus: item.requestStatus,
                            ids: item?.requestId
                        }));
                        setCalendarData(events);
                        setCalendarList(data)
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem("corporate_token")
                        navigate("/corporatelogin")
                    } else {
                        toast.error(err.response.data.message)
                    }
                    setLoader(false)
                })
        }
    }

    useEffect(() => {
        handleCalendarList()
    }, [selectStatus, currentDate, startDate, endDate])

    useEffect(() => {
        if (!token) {
            navigate("/corporatelogin")
        }
    }, [token])

    const handleDatesSet = (dateInfo) => {
        const viewType = dateInfo.view.type;

        if (viewType === "dayGridMonth") {
            const displayedMonth = moment(dateInfo.start).add(15, "days").format("MM");
            const displayedYear = moment(dateInfo.start).add(15, "days").format("YYYY");
            const datas = `${displayedYear}-${displayedMonth}`
            setCurrentDate(datas)
            console.log("Displayed Month and Year:", `${displayedYear}-${displayedMonth}`);
            setSelectStatus(3)
        } else if (viewType === "timeGridWeek") {
            const weekStart = moment(dateInfo.start).format("YYYY-MM-DD");
            const weekEnd = moment(dateInfo.end).subtract(1, "day").format("YYYY-MM-DD");
            console.log("Week Start:", weekStart);
            console.log("Week End:", weekEnd);
            setStartDate(weekStart)
            setEndDate(weekEnd)
            setSelectStatus(2)
        } else if (viewType === "timeGridDay") {
            const selectedDay = moment(dateInfo.start).format("YYYY-MM-DD");
            console.log("Selected Day:", selectedDay);
            setCurrentDate(selectedDay)
            setSelectStatus(1)
        }
    };

    const handleDetails = async (data) => {
        setIsModelOpen(true);

        const newData = calendarList?.filter((pre) => pre.requestId === data.ids);

        if (newData?.length > 0) {
            const firstItem = newData[0];

            const details = {
                username: firstItem?.requestServices?.[0] || "N/A",
                requestStatus: firstItem?.requestStatus || "N/A",
                requestId: firstItem?.requestId || "N/A",
                createdAt: firstItem?.createdAt || "N/A",
                requestDate: firstItem?.requestDate || "N/A",
                startTime: firstItem?.startTime || "N/A",
                endTime: firstItem?.endTime || "N/A",
                NoOfEmployee: firstItem?.NoOfEmployee || "N/A",
                estimatedCost: firstItem?.estimatedCost || "N/A",
            };

            setRequestDetails(details);
        } else {
            console.error("No matching data found for the provided requestId.");
        }
    };

    const options = {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth", // Change to month view
        events: calendarData,
        editable: true,
        selectable: true,
        dayMaxEvents: true,
        datesSet: handleDatesSet,
        eventContent: function (arg) {
            const event = arg.event.extendedProps;
            let backgroundColor = "#EA5F5F";
            switch (event.requestStatus) {
                case "PENDING":
                    backgroundColor = "#f2a205";
                    break;
                case "CONFIRMED":
                    backgroundColor = "#7243de";
                    break;
                case "INPROGRESS":
                    backgroundColor = "#5570f1";
                    break;
                case "COMPLETED":
                    backgroundColor = "#00987c";
                    break;
                default:
                    backgroundColor = "#EA5F5F";
            }

            return (
                <div
                    className="flex items-center gap-2 p-1 cursor-pointer"
                    style={{
                        backgroundColor,
                        color: "#fff",
                        borderRadius: "4px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    onClick={(e) => handleDetails(event)}
                >
                    <div
                        className="flex flex-wrap items-center gap-1"
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <div className="flex items-center">
                            <span className="text-base text-medium pr-1">#{event.ids}</span>
                            <div
                                className="text-base text-medium"
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {event.username}
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
        eventStartEditable: false,
    };

    return (
        <>
            <div className='grid grid-cols-12 fontNew bg-white md:m-4 m-1 md:p-5 p-3  rounded-lg'>
                <div className='col-span-12 mx-3'>
                    <div className='flex justify-between items-center mb-4 border-b pb-4'>
                        <div>
                            <p className='text-gray-400 font-medium'>Today</p>
                            <p className='sm:text-lg text-base font-medium'>{moment(Date.now()).format("D MMMM YYYY")}</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center my-6'>
                        <div className='flex items-center mx-3'>
                            <img src={color1} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Pending</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color2} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Confirmed</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color3} className='w-4' alt="" />
                            <p className='font-medium ms-2'>In Progress</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color4} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Completed</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color5} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Cancelled</p>
                        </div>
                    </div>
                    <div className="full-calendar">
                        <FullCalendar
                            ref={calendarRef}
                            {...options}
                            initialView="dayGridMonth"
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                        />
                    </div>

                </div>
            </div>

            <Dialog
                open={isModelOpen}
                onClose={() => setIsModelOpen(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white sm:p-5 p-3 fontNew sm:w-auto w-[350px] sm:h-auto h-[530px] overflow-auto">
                        <div className='flex items-center justify-between mb-3'>
                            <h1 className='text-lg font-semibold'>Request Details</h1>
                            <img src={Close} className='w-4 h-4 cursor-pointer opacity-75' onClick={() => setIsModelOpen(false)} alt="" />
                        </div>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className='col-span-12 bg-white rounded-[8px] fontNew'>
                                <div className='flex justify-between border-b-2 border-dashed py-5'>
                                    <div>
                                        <p className='text-base font-semibold pb-1'># {requestDetails.requestId || "0"}</p>
                                        <p className='text-sm font-medium text-gray-600'>{moment(requestDetails.createdAt).format("LLL") || ""}</p>
                                    </div>
                                    <div>
                                        {
                                            requestDetails.requestStatus == "PENDING" ?
                                                <button className='bg-[#f2994a1a] py-2 px-3 text-[#F2A205] text-sm rounded-lg'>Pending</button>
                                                : requestDetails.requestStatus == "CONFIRMED" ?
                                                    <button className='bg-[#7424f21a] py-2 px-3 text-[#7424F2] text-sm rounded-lg'>Confirmed</button>
                                                    : requestDetails.requestStatus == "INPROGRESS" ?
                                                        <button className='bg-[#2336E41A] py-2 px-3 text-[#2336E4] text-sm rounded-lg'>In-Progress</button>
                                                        : requestDetails.requestStatus == "COMPLETED" ?
                                                            <button className='bg-[#00987C29] py-2 px-3 text-[#00987C] text-sm rounded-lg'>Completed</button>
                                                            : <button className='bg-[#e423231a] py-2 px-3 text-[#E42323] text-sm rounded-lg'>Cancelled</button>
                                        }
                                    </div>
                                </div>
                                <div className='flex justify-start mt-2'>
                                    <div>
                                        <p className='text-base text-gray-600 mt-2'>Visit Date:</p>
                                        <p className='text-base font-medium pb-1 mt-2'>{moment(requestDetails.requestDate).format("LL") || ""}</p>
                                    </div>
                                    <div className='ms-10'>
                                        <p className='text-base text-gray-600 mt-2'>Visit Time:</p>
                                        <p className='text-base font-medium pb-1 mt-2'>{moment.utc(`1970-01-01T${requestDetails.startTime}Z`).local().format('hh:mm A')} - {moment.utc(`1970-01-01T${requestDetails.endTime}Z`).local().format('hh:mm A')}</p>
                                    </div>
                                </div>
                                <div className='flex justify-start mt-2'>
                                    <div className=''>
                                        <p className='text-base text-gray-600 my-2'>No. of {newName}:</p>
                                        <p className='text-base font-medium pb-1 my-2'>{requestDetails.NoOfEmployee || "0"}</p>
                                    </div>
                                    <div className='ms-10'>
                                        <p className='text-base text-gray-600 my-2'>Cost:</p>
                                        <p className='text-base font-medium pb-1 my-2'>R {requestDetails.estimatedCost || "0"}</p>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-base text-gray-600 my-2'>Service Required:</p>
                                    <p className='text-base font-medium pb-1 my-2'>{requestDetails?.username?.healthService?.serviceName || ""}</p>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </>
    )
}

export default Calender