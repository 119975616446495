
export const BASE_URL = "https://app.welo.health:8000"
export const IMG_URL = "https://app.welo.health:8000/"
export const newName = "Patients"

export const CORPORATE_FORM_API = BASE_URL + "/corporate/create_corporate_customer"

export const CREATE_ACCOUNT_API = BASE_URL + "/corporate/create_account"

export const LOGIN_CORPORATE_API = BASE_URL + "/corporate/login"
export const FORGOT_PASSWORD_API = BASE_URL + "/corporate/forgot_password"
export const VERIFICATION_CORPORATE_API = BASE_URL + "/corporate/forgot_password_verification"
export const RESET_PASSWORD_API = BASE_URL + "/corporate/reset_password"
export const LOGOUT_CORPORATE_API = BASE_URL + "/corporate/logout"

export const GET_DASHBOARD_API = BASE_URL + "/corporate/get_corporate_dashboard"

export const GET_CALENDAR_LIST_API = BASE_URL + "/corporate/get_calender_data"

export const CREATE_REQUEST_API = BASE_URL + "/corporate/create_request"
export const CANCEL_REQUEST_API = BASE_URL + "/corporate/cancle_request_status"
export const LIST_REQUEST_API = BASE_URL + "/corporate/list_request"
export const GET_HEALTH_SERVICE = BASE_URL + "/corporate/get_health_service"
export const GET_EMPLOYEE_API = BASE_URL + "/corporate/get_employee"
export const LIST_REPORTS_API = BASE_URL + "/healthworker/list_reports"
export const LIST_ASSIGN_HELATHWORKER_API = BASE_URL + "/admin/list_assign_healthworker"

export const ADD_EMPLOYEE_API = BASE_URL + "/corporate/add_employee"
export const EDIT_EMPLOYEE_API = BASE_URL + "/corporate/edit_employee"
export const LIST_EMPLOYEE_API = BASE_URL + "/corporate/list_employee"

export const GET_USER_INFO = BASE_URL + "/corporate/get_personal_information"
export const EDIT_USER_INFO = BASE_URL + "/corporate/edit_personal_information"

export const GET_COMPANY_INFO = BASE_URL + "/corporate/get_company_information"
export const EDIT_COMPANY_INFO = BASE_URL + "/corporate/edit_company_information"

export const ADD_COMPANY_ADDRESS_API = BASE_URL + "/corporate/add_company_address"
export const EDIT_COMPANY_ADDRESS_API = BASE_URL + "/corporate/edit_company_address"
export const DELETE_COMPANY_ADDRESS_API = BASE_URL + "/corporate/delete_company_address"

export const CHANGE_PASSWORD_API = BASE_URL + "/corporate/change_password"

export const GET_REPORT_DETAILS_API = BASE_URL + "/corporate/get_report_details"

export const LIST_NOTIFICATION_API = BASE_URL + "/corporate/list_notification"

export const LIST_CHAT_API = BASE_URL + "/chat/get_chat_list"
export const GET_CHAT_MESSAGES_API = BASE_URL + "/chat/get_chat_messages"
export const SEND_MESSAGES_API = BASE_URL + "/chat/send_message"