import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import users from "../../Assets/Image/corporate/user.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { CORPORATE_FORM_API } from '../../Services/Api';
import toast from 'react-hot-toast';
import { Circles } from 'react-loader-spinner';
import logo from "../../Assets/Image/corporate/logos.png";

function CorporateForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [img, setImg] = useState("");
    const [imgs, setImgs] = useState("");
    const [phoneDetails, setPhoneDetails] = useState({
        isoCode: '',
        countryCode: '',
        phoneNumber: ''
    });

    const handleFile = (e, setFieldValue) => {
        const file = e.target.files[0];
        setFieldValue("companyProfileImage", file);
        let imageData = URL.createObjectURL(file);
        setImg(imageData);
    };

    const handleFiles = (e, setFieldValue) => {
        const file = e.target.files[0];
        setFieldValue("profileImage", file);
        let imageData = URL.createObjectURL(file);
        setImgs(imageData);
    };

    const handlePhoneChange = (value, country, e, formattedValue) => {
        formik.setFieldValue('mobileNumber', value);
        setPhoneDetails({
            isoCode: country.countryCode,
            countryCode: country.dialCode,
            phoneNumber: value
        });
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        lastName: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        mobileNumber: Yup.string().required("(Required)"),
        emailAddress: Yup.string()
            .email("Invalid email address")
            .matches(/^([a-zA-Z0-9._%+-]+)@/, "Only Gmail addresses with .com domain are allowed")
            .required("(Required)"),
        dateOfBirth: Yup.date().max(new Date(), "Date of birth cannot be in the future").required("(Required)"),
        companyName: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        companyEmailAddress: Yup.string()
            .email("Invalid email address")
            .matches(/^([a-zA-Z0-9._%+-]+)@/, "Only Gmail addresses with .com domain are allowed")
            .required("(Required)"),
        streetAddress: Yup.string().required("(Required)"),
        aptSuite: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        city: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        state: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed").required("(Required)"),
        zipCode: Yup.string().matches(/^\d+$/, "Zip code must be numeric").required("(Required)"),
        profileImage: Yup.mixed().test(
            "fileSize",
            "File size is too large, maximum is 5MB",
            (value) => !value || (value && value.size <= 5242880)
        ).required("(Image Required)"),
        companyProfileImage: Yup.mixed().test(
            "fileSize",
            "File size is too large, maximum is 5MB",
            (value) => !value || (value && value.size <= 5242880)
        ).required("(Image Required)")
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            emailAddress: "",
            dateOfBirth: "",
            companyName: "",
            companyEmailAddress: "",
            streetAddress: "",
            aptSuite: "",
            city: "",
            state: "",
            zipCode: "",
            profileImage: null,
            companyProfileImage: null
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const rawPhone = phoneDetails.phoneNumber.replace(phoneDetails.countryCode, "");
            setIsLoading(true)

            const formData = new FormData()
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            formData.append("isoCode", phoneDetails.isoCode)
            formData.append("countryCode", `+${phoneDetails.countryCode}`)
            formData.append("phoneNumber", rawPhone)
            formData.append("emailId", values.emailAddress)
            formData.append("dob", values.dateOfBirth)
            formData.append("companyName", values.companyName)
            formData.append("companyEmailId", values.companyEmailAddress)
            formData.append("streetAddress", values.streetAddress)
            formData.append("aptAddress", values.aptSuite)
            formData.append("city", values.city)
            formData.append("state", values.state)
            formData.append("zipCode", values.zipCode)
            formData.append("companyLat", "21.234545")
            formData.append("companyLong", "72.457878")
            if (values.profileImage) {
                formData.append("profilePic", values.profileImage)
            }
            if (values.companyProfileImage) {
                formData.append("companyPic", values.companyProfileImage)
            }

            axios.post(CORPORATE_FORM_API, formData)
                .then((res) => {
                    if (res.status == 200) {
                        toast.success(res.data.message)
                        navigate("/confirmedform");
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    if (err.response.status == 422) {
                        const errs = err.response.data
                        toast.error(errs.errors[0].msg)
                    } else {
                        toast.error(err.response.data.message);
                    }
                    setIsLoading(false)
                });
        },
    });

    return (
        <div className='fontNew'>
            <div className='bg-[#00042C] text-white h-[350px] w-full text-center'>
                <div className='py-9'>
                    <div className='flex justify-center w-full mb-2'>
                        <img src={logo} className='w-18' alt="" />
                    </div>
                    <h1 className='2xl:text-[34px] xl:text-3xl text-2xl'>Corporate Customers</h1>
                    <p className='2xl:text-lg text-base mt-2'>Application Form</p>
                </div>
            </div>
            <div className='flex justify-center bg-white -mt-32 2xl:mx-40 xl:mx-28 lg:mx-24 mx-6'>
                <form onSubmit={formik.handleSubmit}>
                    <div className='md:my-10 my-5 w-full flex justify-center'>
                        <div className='2xl:w-[624px] xl:w-[521px] lg:w-[421px] md:w-[421px] w-full lg:mx-0 mx-3'>
                            <h4 className='text-lg font-semibold'>Personal Details</h4>
                            <div className='flex items-center my-5'>
                                {
                                    imgs ?
                                        <img src={imgs} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full object-cover' alt="" />
                                        :
                                        <img src={users} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full' alt="" />
                                }
                                <div className='ms-5'>
                                    <h4 className='text-base font-semibold'>Profile Image</h4>
                                    <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                    <div className='mt-6'>
                                        <label htmlFor="upload-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] sm:py-3 py-2 sm:px-5 px-3 rounded-lg text-sm font-normal'>Upload image</label>
                                        <input type="file" onChange={(e) => handleFiles(e, formik.setFieldValue)} className='hidden' id='upload-photo' accept='image/*' />
                                        {formik.touched.profileImage && formik.errors.profileImage ? (
                                            <div className="text-red-600 text-sm mt-4">{formik.errors.profileImage}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>First name</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('firstName')} />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Last name</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('lastName')} />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Mobile Number</p>
                                    <PhoneInput
                                        country={"za"}
                                        className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]'
                                        value={formik.values.mobileNumber}
                                        onChange={handlePhoneChange}
                                        inputProps={{ name: 'mobileNumber', required: true, autoFocus: true }}
                                    />
                                    {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.mobileNumber}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Email Address</p>
                                    <input type="email" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('emailAddress')} />
                                    {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.emailAddress}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Date of Birth</p>
                                    <input type="date" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('dateOfBirth')} />
                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.dateOfBirth}</div>
                                    ) : null}
                                </div>
                            </div>
                            <h4 className='text-lg font-semibold mt-8'>Company Details</h4>
                            <div className='flex items-center my-5'>
                                {
                                    img ?
                                        <img src={img} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full object-cover' alt="" />
                                        :
                                        <img src={users} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full' alt="" />
                                }
                                <div className='ms-5'>
                                    <h4 className='text-base font-semibold'>Company Profile Image</h4>
                                    <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                    <div className='mt-6'>
                                        <label htmlFor="upload-company-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] sm:py-3 py-2 sm:px-5 px-3 rounded-lg text-sm font-normal'>Upload image</label>
                                        <input type="file" onChange={(e) => handleFile(e, formik.setFieldValue)} className='hidden' id='upload-company-photo' accept='image/*' />
                                        {formik.touched.companyProfileImage && formik.errors.companyProfileImage ? (
                                            <div className="text-red-600 text-sm mt-4">{formik.errors.companyProfileImage}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Company Name</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('companyName')} />
                                    {formik.touched.companyName && formik.errors.companyName ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.companyName}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Company Email Address</p>
                                    <input type="email" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('companyEmailAddress')} />
                                    {formik.touched.companyEmailAddress && formik.errors.companyEmailAddress ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.companyEmailAddress}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Street Address</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('streetAddress')} />
                                    {formik.touched.streetAddress && formik.errors.streetAddress ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.streetAddress}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Apt/Suite</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('aptSuite')} />
                                    {formik.touched.aptSuite && formik.errors.aptSuite ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.aptSuite}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>City</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('city')} />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.city}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 lg:col-span-6 2xl:col-span-6 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>State</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('state')} />
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.state}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <p className='text-sm font-medium mb-3'>Zip Code</p>
                                    <input type="text" className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' placeholder='Enter' {...formik.getFieldProps('zipCode')} />
                                    {formik.touched.zipCode && formik.errors.zipCode ? (
                                        <div className="text-red-600 text-sm mt-1">{formik.errors.zipCode}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='flex justify-center mt-8'>
                                <button type='submit' disabled={isLoading} className='bg-[#00987C] text-white sm:w-[226px] w-[180px] h-[42px] rounded-lg'>
                                    {
                                        isLoading ?
                                            <div className='flex justify-center'>
                                                <Circles
                                                    height="25"
                                                    width="25"
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                            :
                                            "Submit"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CorporateForm;
